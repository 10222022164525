import { useState } from 'react';

export const initialValues = {
  currentVariant: null,
};

export default function Page() {
  const [currentVariant, setCurrentVariant] = useState<any>();

  return {
    data: {
      currentVariant,
    },
    methods: {
      setCurrentVariant,
    },
  };
}
